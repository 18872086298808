
@media print {

    @font-face {
        font-family: 'Titillium Web';
        font-style: normal;
        font-weight: 400;
        src: url('./fonts/titillium-web/titillium-web-v8-latin-regular.eot'); /* IE9 Compat Modes */
        src: local('Titillium Web Regular'), local('TitilliumWeb-Regular'), url('./fonts/titillium-web/titillium-web-v8-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('./fonts/titillium-web/titillium-web-v8-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('./fonts/titillium-web/titillium-web-v8-latin-regular.woff') format('woff'), /* Modern Browsers */
        url('./fonts/titillium-web/titillium-web-v8-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('./fonts/titillium-web/titillium-web-v8-latin-regular.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
    }

    /* titillium-web-700 - latin */
    @font-face {
        font-family: 'Titillium Web';
        font-style: normal;
        font-weight: 700;
        src: url('./fonts/titillium-web/titillium-web-v8-latin-700.eot'); /* IE9 Compat Modes */
        src: local('Titillium Web Bold'), local('TitilliumWeb-Bold'), url('./fonts/titillium-web/titillium-web-v8-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('./fonts/titillium-web/titillium-web-v8-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
        url('./fonts/titillium-web/titillium-web-v8-latin-700.woff') format('woff'), /* Modern Browsers */
        url('./fonts/titillium-web/titillium-web-v8-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
        url('./fonts/titillium-web/titillium-web-v8-latin-700.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
    }

    body {
        height: auto;
    }

    a[href]:after {
        content: "" !important;
    }

    .btn-toolbar {
        display: none;
    }

    footer {
        display: none;
        position: fixed;
        bottom: 0;
    }

    header {
        display: none;
        position: fixed;
        top: 0;
    }

    .ql-editor {
        height: auto;
    }

    .table-sm {
        font-size: 8pt;
    }

    .table-sm > thead > tr > th,
    .table-sm > tbody > tr > th,
    .table-sm > tfoot > tr > th,
    .table-sm > thead > tr > td,
    .table-sm > tbody > tr > td,
    .table-sm > tfoot > tr > td {
        padding: 2px 4px;
    }

    .reception-print-data-row {
        margin: 0;
        padding: 0;
    }

    .reception-print-data {
        font-size: 10px !important;
        line-height: 12px !important;
        padding: 2px 5px !important;
        margin: 0 !important;
    }
}
