
@font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/titillium-web/titillium-web-v8-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Titillium Web Regular'), local('TitilliumWeb-Regular'), url('./fonts/titillium-web/titillium-web-v8-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/titillium-web/titillium-web-v8-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('./fonts/titillium-web/titillium-web-v8-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('./fonts/titillium-web/titillium-web-v8-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./fonts/titillium-web/titillium-web-v8-latin-regular.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}

/* titillium-web-700 - latin */
@font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    src: url('./fonts/titillium-web/titillium-web-v8-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Titillium Web Bold'), local('TitilliumWeb-Bold'), url('./fonts/titillium-web/titillium-web-v8-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/titillium-web/titillium-web-v8-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./fonts/titillium-web/titillium-web-v8-latin-700.woff') format('woff'), /* Modern Browsers */
    url('./fonts/titillium-web/titillium-web-v8-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./fonts/titillium-web/titillium-web-v8-latin-700.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}

html {
    height: 100%;
    font-family: 'Titillium Web', 'Open Sans', sans-serif,"Helvetica Neue", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
}

body {
    height: 100%;
    font-family: 'Titillium Web', 'Open Sans', sans-serif,"Helvetica Neue", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
}

.full-height {
    height: 100%;
}

.full-width {
    width: 100%;
}

.inline {
    display: inline-block;
}

.no-border {
    border: 0;
}

.mt-15 {
    margin-top: 15px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mv-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.ml-10 {
    margin-left: 10px;
}

.mh-10 {
    margin-left: 10px;
    margin-right: 10px;
}

.no-margin {
    margin: 0;
}

.row.gutter-10 {
    margin-left: -5px;
    margin-right: -5px;
}

.highlight {
    font-weight: bold;
}

.row.gutter-10 > [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px;
}

.table.no-border > thead > tr > th,
.table.no-border > tbody > tr > th,
.table.no-border > tfoot > tr > th,
.table.no-border > thead > tr > td,
.table.no-border > tbody > tr > td,
.table.no-border > tfoot > tr > td {
    border: 0 solid #ddd;
}

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
    border-bottom-width: 2px;
}


.help-block {
    padding: 1px;
}

.clear-right-padding {
    padding-right: 0;
}

.row-flex {
    display: flex;
    flex-wrap: wrap;
}

.row-flex .col-md-6 {
    flex: 1 1 100%;
}

@media (min-width: 992px) {
    .row-flex .col-md-6 {
        flex: 1 1 50%;
    }
}

.flex {
    display: flex;
}

.flex-center {
    align-items: center;
}

.flex-start {
    align-items: start;
}

.flex-row {
    flex-direction: row;
}

.flex-col {
    flex-direction: column;
}

.flex-stretch {
    flex: 1 1 auto;
}

.flex-shrink {
    flex: 0 1 auto;
}

.flex-nowrap {
    flex-wrap: nowrap;
}

.scroll-panel {
    max-height: 100%;
    overflow-x: auto;
}

table td.shrink {
    white-space: nowrap;
}

table td.expand {
    width: 99%
}

.wrap-text {
    overflow-wrap: break-word;
}

.nowrap {
    white-space: nowrap;
}

.formatted-text {
    white-space: pre-wrap;
}

.strong {
    font-weight: 700;
}

.circle {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    padding: 0;
    border-style: solid;
    border-width: 2px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.1;
}

.circle.small {
    width: 18px;
    height: 18px;
    font-size: 14px;
    line-height: 1;
}

.circle.black {
    border-color: #000;
    color: #000;
}
.circle.white-bg {
    background-color: #fff;
}

.notification {
    /* circle shape, size and position */
    position: absolute;
    right: -0.7em;
    top: -0.7em;
    min-width: 2em; /* or width, explained below. */
    height: 2em;
    border-radius: 1em; /* or 50%, explained below. */
    border: 0.05em solid white;
    background-color: red;
    /* number size and position */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8em;
    color: white;
}

.table-super-condensed > thead > tr > th,
.table-super-condensed > tbody > tr > th,
.table-super-condensed > tfoot > tr > th,
.table-super-condensed > thead > tr > td,
.table-super-condensed > tbody > tr > td,
.table-super-condensed > tfoot > tr > td {
    padding: 2px 4px;
}

.table-super-condensed .form-group {
    margin: 0;
}

section.row {
    margin-top: 15px;
}

.row-m-t {
    margin-top: 20px
}

.dropdown-menu {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}

.open > .dropdown-menu {
    min-width: 100%;
}

    .app-content-container {
    background-color: #eee;
}


.at-panel-rounded {
    border-radius: 6px;
    overflow: hidden;
}

.at-panel-bg {
    background-color: #fff;
}

.at-panel {
    background-color: #fff;
    padding: 5px;
}

.outer-panel {
    background-color: #eee;
    margin: 6px;
    padding: 15px;
}

.form-panel {
    margin: 10px 0;
    background-color: #fff;
    padding: 5px 15px;
    border-radius: 6px;
}

.form-section-heading {
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.section-heading {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    border-bottom: solid 1px #333;
    margin-bottom: 6px;
}

.red {
    color: #f00;
}

.black-text {
    color: #000000;
}

.error-message {
    color: #a94442;
}

.warning-message {
    color: #FF9900;
}

.warning {
    background-color: #FCBB74;
}

.center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}
.right {
    float: right;
}

.underline {
    border-bottom: solid 1px #888;
}

.has-feedback {
    min-height: 80px;
    margin-bottom: 0;
}

.glyph-button {
    margin: 0 6px;
    cursor: pointer;
}

.btn-toolbar-spaced {
    display: flex;
    justify-content: space-around;
}

.btn-toolbar-centered {
    display: flex;
    justify-content: center;
}

.btn-no-padding {
    padding: 0;
}

.list-no-margin {
    margin: 0;
}

.list-separator li + li {
    border-top: solid 1px #ddd;
}

.reservation-details-form {
}

.reservation-type-button {
    border: solid 1px #ccc;
    padding: 4px;
    border-radius: 3px;
    margin-bottom: 10px;
}

.reservation-type-button span{
    font-size: larger;
    font-weight: bold;
}

.btn-basic {
    color: black;
    background-color: #ddd;
    border-color: #ddd;
}
.btn-basic:focus,
.btn-basic.focus {
    color: black;
    background-color: #bbb;
    border-color: #bbb;
}

.btn-basic:hover {
    color: black;
    background-color: #bbb;
    border-color: #bbb;
}

.btn-basic:active,
.btn-basic.active,
.open > .dropdown-toggle.btn-basic {
    color: black;
    background-color: #bbb;
    border-color: #bbb;
}

    .btn-basic:active:hover,
    .btn-basic.active:hover,
    .open > .dropdown-toggle.btn-basic:hover,
    .btn-basic:active:focus,
    .btn-basic.active:focus,
    .open > .dropdown-toggle.btn-basic:focus,
    .btn-basic:active.focus,
    .btn-basic.active.focus,
    .open > .dropdown-toggle.btn-basic.focus {
        color: black;
        background-color: #bbb;
        border-color: #bbb;
    }

.btn-basic:active,
.btn-basic.active,
.open > .dropdown-toggle.btn-basic {
    background-image: none;
}

.btn-basic.disabled:hover,
.btn-basic[disabled]:hover,
fieldset[disabled] .btn-basic:hover,
.btn-basic.disabled:focus,
.btn-basic[disabled]:focus,
fieldset[disabled] .btn-basic:focus,
.btn-basic.disabled.focus,
.btn-basic[disabled].focus,
fieldset[disabled] .btn-basic.focus {
    background-color: #eee;
    border-color: #eee;
}

.btn-basic .badge {
    color: #fff;
    background-color: #666;
}

.btn-plain {
    color: black;
    background-color: #fff;
    border-color: #ddd;
}

.btn-plain:focus,
.btn-plain.focus {
    color: black;
    background-color: #fff;
    border-color: #ddd;
}

.btn-plain:hover {
    color: black;
    background-color: #fff;
    border-color: #ddd;
}

.btn-plain:active,
.btn-plain.active,
.open > .dropdown-toggle.btn-plain {
    color: black;
    background-color: #fff;
    border-color: #ddd;
}

.btn-plain:active:hover,
.btn-plain.active:hover,
.open > .dropdown-toggle.btn-plain:hover,
.btn-plain:active:focus,
.btn-plain.active:focus,
.open > .dropdown-toggle.btn-plain:focus,
.btn-plain:active.focus,
.btn-plain.active.focus,
.open > .dropdown-toggle.btn-plain.focus {
    color: black;
    background-color: #fff;
    border-color: #ddd;
}

.btn-plain:active,
.btn-plain.active,
.open > .dropdown-toggle.btn-plain {
    background-image: none;
}

.btn-plain.disabled:hover,
.btn-plain[disabled]:hover,
fieldset[disabled] .btn-plain:hover,
.btn-plain.disabled:focus,
.btn-plain[disabled]:focus,
fieldset[disabled] .btn-plain:focus,
.btn-plain.disabled.focus,
.btn-plain[disabled].focus,
fieldset[disabled] .btn-plain.focus {
    background-color: #eee;
    border-color: #eee;
}

.btn-plain .badge {
    color: #fff;
    background-color: #666;
}

.table > tbody > tr > td.table-second-row {
    border-top: 0 solid #ddd;
    padding-top: 0;
}

.table-borderless > tbody > tr > td, .table-borderless > thead > tr > th {
    border: none;
}

.value-box {
    min-width: 160px;
    min-height: 40px;
    padding: 0 4px;
    margin-left: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.button-panel {
    display: flex;
    justify-content: space-around;
    margin: 20px 0 10px;
}


.separated-list li + li {
    border-top: 1px solid #ccc;
    margin-top: 5px;
    padding-top: 5px;
}

.date-picker {
    /*float: left;*/
    display: inline-block;
    width: 200px;
}

.dropdown-item {
    cursor: pointer;
}

.button-group {
    width: 100%;
}

.selected-item {
    background: #c6e4fe;
}

.selected-item-cancelled {
    background: #ffa9a9;
}

.app-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.app-content-container {
    position: absolute;
    height: calc(100% - 50px);
    top: 50px;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
}

.app-content-content {
    padding-left: 15px;
    padding-right: 15px;
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: auto;
}

.section-header {
    clear: both;
    position: relative;
    width: 100%;
    float: left;
}

.sub-heading {
    font-size: 18px;
}

.page-heading {
    clear: both;
    position: relative;
    width: 100%;
    float: left;
    margin-left: 5px;
}

.venue-heading {
    font-size: 24px;
}

.section-actions {
    margin: 10px;
    display: flex;
    align-items: center;
}

.section-actions.right {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
}

.section-actions>button{
    margin: 0 6px;
}

.section-actions > a {
    margin: 0 6px;
}

.sub-section-wrapper {
    width: 100%;
    float: left;
    background: #D7D7D7;
    border-bottom: 1px solid #D7D7D7;
    border-left: 1px solid #E9E9E9;
    border-right: 1px solid #E9E9E9;
    border-top: 1px solid #F0F0F0;
    border-radius: 5px;
    margin-bottom: 15px;
}

.sub-section-panel {
    background: #fff;
    margin-top: 45px;
    padding: 10px 5px 5px;
}

.sub-section-panel-240px {
    min-height: 240px;
}

.time-select {
    display: inline-block;
    width: 65px;
    margin: 0 3px;

}

.routes-wrapper {
    overflow: auto;
    height: 100%;
}


.due-payments-list {
    float: left;
    width: 100%;
}
.due-payments-title {
    margin-top: 8px;
    margin-bottom: 8px;
}

.venue-item-list {
    float: left;
    width: 100%;
}

.venue-item-title {
    margin-top: 8px;
    margin-bottom: 8px;
}

.venue-registration-panel {
    float: left;
    width: 100%;
}

.venue-registration-panel-title {
    margin-top: 8px;
    margin-bottom: 8px;
}

.venue-integrations-panel {
    float: left;
    width: 100%;
}

.venue-integrations-panel-title {
    margin-top: 8px;
    margin-bottom: 8px;
}

.colour-selection {
    width: 16px;
    height: 16px;
    margin: 0 2.5px;
    text-decoration: none;
    color: white;
    display: inline-block;
    border: 1px solid transparent;
    vertical-align: top;
}

.colour-selection:hover {
    border: 1px solid black;
}

.colour-selection-selected {
    display: block;
    height: 10px;
    width: 10px;
    left: -1px;
    top: -1px;
    margin: 3px;
    overflow: hidden;
    position: relative;
    vertical-align: top;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuMTnU1rJkAAAANklEQVQoU63KMRIAMAgCQf7/6USZs3BCma0U0BencGY9aLwveotrIosBp3kwyBaqjc6IsjyQLjQtZ5lz92hnAAAAAElFTkSuQmCC);
}

.plain-list {
    list-style: none;
    padding: 0;
}


.card-list {
    width: 100%;
    list-style: none;
    display: inline-block;
    padding: 0;
}

.card {
    padding-right: 20px;
    width: 25%;
    margin-bottom: 25px;
    float: left;
}

@media (max-width: 600px) {
    .card {
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }
}

@media (min-width: 601px) and (max-width: 1100px) {
    .card {
        width: 50%;
    }
}

@media (min-width: 1101px) and (max-width: 1500px) {
    .card {
        width: 33%;
    }
}

.card-content {
    background-color: #f8f8f8;
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #D7D7D7;
    border-left: 1px solid #E9E9E9;
    border-right: 1px solid #E9E9E9;
    border-top: 1px solid #F0F0F0;
    border-radius: 3px;
}

.card-header {
    padding: 10px 10px 0;
}

.card-header h2 {
    font-size: 20px;
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.card-body {
    height: 80px;
}

.card-footer {
    height: 35px;
}

.card-edit {
    float: right;
}

.error-placeholder {
    min-height: 30px;
    margin-bottom: 5px;
}

.main-nav li .glyphicon {
    margin-right: 10px;
}

.navbar-alpha-blue {
    background-color: #1D67A7;
    border-color: #080808;
}

.server-connection-status {
    margin: 8px;
}

.header-item {
    color: white;
}

.app-version {
    color: #999;
}

.app-user {
    color: #eee;
}

@media (max-width: 1024px) {
    .app-version {
        display: none;
    }
}

.main-nav li a {
    color: white;
}

.main-nav li a:hover,
.main-nav li a:hover {
    opacity: 1;
    background-color: rgba(90, 90, 90, 0.75);
}

/* Highlighting rules for nav menu items */
.main-nav li a.active,
.main-nav li a.active:hover,
.main-nav li a.active:focus {
    background-color: rgba(120,120,120,0.75);
    opacity: 1;
    color: white;
}

.navbar-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
}

/* Keep the nav menu independent of scrolling and on top of other items */
.main-nav {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    z-index: 1;
    overflow: hidden;
}

.main-nav .navbar {
    overflow: auto;
}

.header-alert {
    clear: both;
    position: relative;
    width: 100%;
    float: left;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    padding: 5px;
    margin: 0 0 3px 0;
}

.server-connection-status {
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.server-connection-status-popup {
    position: absolute;
    right: 0;
    width: 260px;
    margin-top: 8px;
    padding: 10px;
    background-color: #fff;
    border: solid 1px #333;
    border-top: 0;
    border-radius: 0 0 5px 5px;
    cursor: default;
}

.server-connection-status-popup label {
    margin-right: 8px;
}

.form-row {
    margin-bottom: 15px;
}

.data-form {
    margin-bottom: 20px;
}

.data-form .form-group {
    max-width: 400px;
}

.btn-panel {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    max-width: 400px;
}

.data-form-section h4 {
    border-bottom: solid 1px #999;
    max-width: 400px;
}

/* http://nicolasgallagher.com/pure-css-speech-bubbles/demo/ */
.help-icon {
    margin-left: 10px;
    color: #007bff;
    position: relative;
}

.help-text {
    visibility: hidden;
    width: 200px;
    background-color: #f8f8f8;
    color: #000;
    padding: 5px 8px;
    border-color: #222;
    border-width: 1px;
    border-radius: 6px;
    border-style: solid;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    top: -10px;
    margin-left: 20px;
    z-index: 9999;
}

.help-text:before {
    content: '';
    top: 10px;
    bottom: auto;
    left: -15px;
    position: absolute;
    border-style: solid;
    display: block;
    width: 0;
    border-width: 8px 15px 8px 0;
    border-color: transparent #222;
}

.help-text:after {
    content: '';
    top: 11px;
    bottom: auto;
    left: -13px;
    position: absolute;
    border-style: solid;
    display: block;
    width: 0;
    border-width: 7px 14px 7px 0;
    border-color: transparent #f8f8f8;
}

.help-icon:hover .help-text {
    visibility: visible;
}

.registration-form {
    font-size: 20px;
}

.registration-form .form-control {
    font-size: 18px;
    height: 46px;
}

.registration-form .btn {
    font-size: 20px;
}

.registration-form .form-control-feedback {
    line-height: 50px;
}

.registration-form .has-feedback {
    min-height: 100px;
}

.registration-form .form-group {
    max-width: 400px;
}

.registration-form p {
    min-height: 20px;
}

.registration-form-terms {
    overflow: auto;
    max-height: 300px;
    border: solid 1px #ccc;
    padding: 6px;
}

.registration-terms-name {
    color: #66A3E0;
}

.form-sub-heading {
    font-weight: bold;
    border-bottom: 3px solid #999999;
    margin: 0 0 10px 0;
    font-size: 18px;
}

.control-wrapper {
    position: relative;
}

.control-wrapper label {
    margin-bottom: 0;
}

.control-wrapper .form-control-feedback {
    margin-top: -4px;
}


.login-page {
    background-image: url("./images/logon_bg.png");
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-attachment: fixed;
    height: 100%;
    overflow: auto;
}

.login-page::before {
    content: '';
    background-color: rgba(0, 0, 0, 0.7);
    display: block;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
}

.login-content {
    position: relative;
}

.login {
    margin: 120px auto 0;
    padding: 20px;
    max-width: 600px;
    border-radius: 8px;
    color: #000;
    background: rgba(255,255,255,0.6);
}

.login-title {
    text-align: center;
    padding-bottom: 30px;
}
.forgotten-password-link {
    font-size: 14pt;
    color: #0e4573;
    font-weight: bold;
}


.header-img {
    width: 36px;
    height: 36px;
    margin-left: -7px;
    margin-top: -7px;
}

/* overlay stuff taken from here: https://tympanus.net/Development/FullscreenOverlayStyles/index11.html  */
.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 50px;
    padding: 25px;
    left: 0;
    overflow-x: auto;
    z-index: 999;
    background: rgba(40,40,40,0.9);
    display: flex;
}

.overlay-content {
    background: white;
    border-radius: 6px;
    padding: 30px;
    display: flex;
    flex: 1 1 auto;
    overflow: auto;
    flex-direction: column;
}

.overlay-content-noscroll {
    height: 100%;
}

.overlay-content > * {
    flex: 1 auto;
}

.eventPage {
    flex: 1 1 100%;
    display: flex;
    flex-direction: row;
}

.eventForm {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
}

.event-form-form {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.event-form-tabs {
    display: flex;
}

.event-form-reservation-summary {
    display: flex;
    flex-direction: column;
}

.eventform-reservation {
    border: solid 1px #ccc;
    margin-bottom: 6px;
}

.eventform-reservation-row {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding: 0 4px;
}

.eventform-reservation-item {
    flex: 1 1 0;
    margin: 4px;
    display: flex;
    align-items: center;
}

.eventform-reservation-item h4 {
    margin: 0;
}

.eventform-reservation-participants {
    max-width: 70px;
    display: inline-block;
    flex: 0 0 auto;
    overflow: hidden;
    padding: 4px;
}

.eventform-reservation-participants > span {
    margin-right: 4px;
}

.eventform-reservation-participants>input {
    width: 70px;
    display: inline-block;
}

.eventform-reservation-delete {
    padding-top: 8px;
}

.separator {
    border-top: 1px solid #ddd;
    padding-top: 5px;
}

.eventform-reservation-times {
    display: inline-block;
    flex: 0 0 auto;
    padding: 4px;
}

.eventform-reservation-times .rdt-inline {
    margin: 0 4px 0 0;
}

.eventform-reservation-format {
    display: inline-block;
    flex: 1 1 auto;
    overflow: hidden;
}

.eventform-reservation-spaces>input {
    width: 70px;
    display: inline-block;
}

.eventCustomers-addCustomerPanel {
    visibility: collapse;
    height: 0px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #eee;
    padding: 6px;
    overflow: hidden;
    transition: height 0.4s ease-out, visibility 0s 0.4s;
}

.eventCustomers-addCustomerPanel.open {
    visibility: visible;
    height: 570px;
    transition: height 0.4s;
}

.eventCustomerList-name {
    font-size: larger;
    font-weight: bold;
}

.eventCustomerList-email {
    font-size: smaller;
}

.eventCustomerList-company {
    font-size: smaller;
}

.eventCustomerList-phone {
}

.eventCustomerList-phoneNumber {
    font-size: smaller;
    margin-right: 8px;
}

.eventCustomerList-actions {
    text-align: right;
}

.email-template-page-attachment-name {
    font-size: larger;
}

.email-template-page-attachment-size {
    margin-left: 10px;
}

.email-template-page-attachment-created {
    margin-left: 10px;
}

.customer-search-results-wrapper {
    width: 100%;
    min-height: 200px;
    overflow-x: hidden;
    overflow: auto;
}

.customer-search-table {
    width: 100%;
}

.bill-totals {
    margin-bottom: 10px;
}

.bill-total {
    font-weight: bold;
    margin-right: 4px;
}


.bill-total-paid {
    color: #5cb85c;
}

.bill-total-outstanding {
    color: rgb(169, 68, 66);
}

.bill-item-panel {
    margin-bottom: 8px;
}

.bill-fee-disc-panel-fld {
    display: inline-block;
    max-width: 120px;
    margin-bottom: 15px;
}

.bill-fee-disc-panel-btn {
    margin: 0 5px;
}


.bill-fee-disc-panel-focused-fld {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
}

/* Effects */
.overlay-form {
    visibility: hidden;
    -webkit-transform: translateY(60%) scale(0);
    transform: translateY(60%) scale(0);
    -webkit-transition: -webkit-transform 0.4s, visibility 0s 0.4s;
    transition: transform 0.4s, visibility 0s 0.4s;
}

.overlay-form.open {
    visibility: visible;
    -webkit-transform: translateY(0%) scale(1);
    transform: translateY(0%) scale(1);
    -webkit-transition: -webkit-transform 0.4s;
    transition: transform 0.4s;
}

.hdr-selection {
    padding: 8px 0;
    float: left;
    margin-right: 10px;
}

.hdr-selection-dropdown {
    margin-top: -9px;
}

.hdr-client {
    padding: 10px 0 10px 10px;
    float: left;
    color: #999999;
    font-size: 20px;
    margin-right: 15px
}

.diary-page {
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    bottom: 15px;
}

.diary-page-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    overflow: hidden;
}

.diary-page-header {
    margin: 5px 10px 5px 10px;
    min-height: 34px;
    flex: 0 0 auto;
    display: flex;
}

.diary-page-header-date {
    flex: 1 1 auto;
}

.diary-page-search {
    flex: 1 1 auto;
    background-color: #ddd;
    padding: 2px 4px;
    border-radius: 4px;
    position: relative;
}

.diary-page-search-box {
    display: flex;
    align-items: center;
}

.diary-page-search .glyphicon {
    flex: 0 0 auto;
    margin-right: 4px;
}

.diary-page-search-term {
    flex: 1 1 auto;
}

.diary-search-results {
    position: absolute;
    z-index: 2000;
    min-width: 100%;
    max-width: 400px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px;
    padding: 10px;
    overflow: auto;
    max-height: 85vh;
}

.diary-search-results ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.diary-search-results li {
    padding: 5px 0;
}

.diary-search-results li:not(:last-child) {
    border-bottom: solid 1px #ccc;
}


@media (max-width: 450px) {
    .diary-page-header { margin: 5px 10px 5px 10px; }
}

.diary-venue-selection {
    float: left;
    margin-right: 10px;
}

.reception-page {
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    bottom: 15px;
}

.reception-page .section-header  {
    margin-bottom: 15px;
}

.reception-page-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    overflow: hidden;
}

.reception-wrapper {
    flex: 1 1 100%;
    display: flex;
    overflow: hidden;
}

.reception-reg-col {
}

.reception-events-col {
}

.reception-heading {
    text-align: center;
}
.reception-view-selection {
    display: flex;
    border: solid 1px #bbb;
    border-radius: 3px;
    padding: 3px;
    margin-right: 5px;
}

.reception-view-selection>button {
    margin: 0 3px;
}


.reception-page-header {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 3px 0 15px 0;
}

.reception-page-header-actions {
    display: flex;
    align-items: center;
}

.reception-page-header-actions > * {
    margin: 0 6px;
}

.reception-event-list {
    flex: 1 1 auto;
    overflow-x: auto;
}

.reception-kiosk-filter {
    margin-right: 15px;
    float: left;
}

.reception-age-col {
    min-width: 30px;
    text-align: center;
    display: inline-block;
}

.reception-kiosk-col-label {
    display: block;
}

.reception-registration {
    border: 2px solid #ccc;
    border-radius: 3px;
    padding: 4px;
    margin-bottom: 10px;
    min-height: 45px;
    background: #F6F6F6;
}

.reception-registrations .selected {
    border-color: green;
    background: #EBFDEA;
}

.reception-page-reservation-summary {
    padding: 5px 10px 10px 10px;
}

.reception-event {
    margin-bottom: 25px;
    border-radius: 4px;
}

.reception-event-heading {
    flex: 1 1 100%;
    flex-wrap: nowrap;
    display: flex;
    flex-direction: column;
    padding: 6px 0;
    background-color: #fff;
}

.reception-event-header,
.reception-reservation-header {
    flex: 1 1 100%;
    flex-wrap: nowrap;
    display: flex;
    margin: 2px 0;
    cursor: pointer;
}

.reception-event-customer-names {
    padding: 2px 10px;
    font-weight: bold;
    background-color: #fff;
}

.reception-item-panel-status {
    font-size: 20px;
    margin-right: 10px;
}

.reception-event-time,
.reception-reservation-time {
    flex: 0 0 70px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}

.reception-event-name {
    flex: 1 1 auto;
    border-left: #ddd solid 1px;
    padding-left: 6px;
}

.reception-reservation-resource {
    flex: 0 0 120px;
    overflow: hidden;
    border-left: #ddd solid 1px;
    padding-left: 6px;
}

.reception-reservation-name {
    flex: 1 1 auto;
    border-left: #ddd solid 1px;
    padding-left: 6px;
}

.reception-reservation-type {
    flex: 0 0 80px;
    overflow: hidden;
    border-left: #ddd solid 1px;
    padding-left: 6px;
    text-align: center;
}

.reception-reservation-booked {
    flex: 0 0 90px;
    overflow: hidden;
    border-left: #ddd solid 1px;
    padding-left: 6px;
    text-align: left;
    font-size: 16px;
}

.reception-reservation-registered {
    flex: 0 0 90px;
    overflow: hidden;
    border-left: #ddd solid 1px;
    padding-left: 6px;
    text-align: left;
    font-size: 16px;
}

.reception-reservation-checked-in {
    flex: 0 0 90px;
    overflow: hidden;
    border-left: #ddd solid 1px;
    padding-left: 6px;
    text-align: left;
    font-size: 16px;
}

.reception-event-count-complete {
    color: rgb(92, 184, 92);
}

.reception-actions {
    flex: 0 0 75px;
    overflow: hidden;
    border-left: #eee solid 1px;
    padding-left: 6px;
}

.reception-booking-header {
    flex: 1 1 100%;
    flex-wrap: nowrap;
    display: flex;
    margin: 2px 0 2px 6px;
    padding: 6px 0 1px 0;
    border-bottom: solid 1px #333;
    font-weight: bold;
}

.reception-booking-bill-number {
    flex: 0 0 75px;
    text-align: center;
    font-size: 12px;
    padding-left: 4px;
    padding-top: 1px;
}

.reception-booking-customer {
    flex: 0 0 150px;
    overflow: hidden;
    border-left: #eee solid 1px;
    padding-left: 6px;
}

.clickable-icon {
    cursor: pointer;
}

.reception-booking-notes {
    flex: 1 1 auto;
    display: flex;
    border-left: #ddd solid 1px;
    padding-left: 6px;
    overflow: hidden;
    white-space: nowrap;
}

.reception-booking-outstanding-amount {
    flex: 0 0 100px;
    overflow: hidden;
    border-left: #eee solid 1px;
    padding-left: 6px;
    color: red;
    font-weight: bold;
    font-size: 16px;
}

.reception-counts-filler {
    flex: 0 0 270px;
    overflow: hidden;
    border-left: #ddd solid 1px;
    padding-left: 6px;
    text-align: left;
}

.reception-booking-actions {
    flex: 0 0 75px;
    overflow: hidden;
    border-left: #eee solid 1px;
    padding-left: 6px;
    display: flex;
    flex-direction: column;
}

.reception-booking-buttons {
    padding-left: 80px;
}

.reception-booking-registration {
    flex: 1 1 100%;
    flex-wrap: nowrap;
    display: flex;
    flex-direction: column;
    margin: 0 0 0 10px;
    border-bottom: solid 1px #ccc;
    padding: 2px 0;
    align-items: stretch;
    position: relative;
    background-color: #fff;
}

.reception-booking-registration-main-row {
    flex: 1 1 100%;
    flex-wrap: nowrap;
    display: flex;
    padding: 2px 0;
    align-items: center;
    position: relative;
}

.reception-booking-registration-actions {
    flex: 0 0 75px;
    overflow: hidden;
    text-align: left;
}

.reception-booking-registration-name {
    flex: 0 0 150px;
    overflow: hidden;
}

.reception-booking-registration-age {
    flex: 0 0 30px;
    overflow: hidden;
    text-align: center;
}

.reception-booking-registration-cls {
    flex: 0 0 90px;
    overflow: hidden;
}

.reception-booking-registration-activity {
    flex: 1 1 100%;
    overflow: hidden;
    display: flex;
}
.reception-booking-registration-tags {
    flex: 1 1 100%;
    overflow: hidden;
    display: flex;
}

.reception-booking-registration-notes {
    flex: 1 1 100%;
    flex-wrap: nowrap;
    display: flex;
    margin: 2px 0;
    overflow: hidden;
    white-space: nowrap;
}

.reception-booking-registration-time {
    flex: 0 0 90px;
    overflow: hidden;
    text-align: right;
    white-space: nowrap;
    padding-right: 6px;
    font-size: 12px;
    line-height: 14px;
    color: #999;
}

.reception-booking-registration-info-web {
    flex: 0 0 40px;
    overflow: hidden;
}

.reception-booking-registration-info-checked-in {
    flex: 0 0 30px;
    overflow: hidden;
    cursor: pointer;
}

.reception-booking-registration-info-video {
    flex: 0 0 30px;
    overflow: hidden;
}

.reception-booking-registration-info-hide-results {
    flex: 0 0 30px;
    overflow: hidden;
}

.reception-booking-registration-payment {
    flex: 0 0 60px;
    overflow: hidden;
    text-align: right;
}

.reception-booking-registration-pay {
    flex: 0 0 75px;
    overflow: hidden;
    text-align: center;
}

.reception-booking-registration-custom-fields {
    padding-left: 75px;
    flex: 1 1 100%;
    display: flex;
    flex-wrap: wrap;
}

.reception-booking-registration-custom-fields div {
    padding-right: 12px;
}

.reception-booking-no-registrations {
    text-align: center;
    font-weight: bold;
    color: darkorange;
    background-color: #fff;
    padding: 4px 0;
    margin-left: 10px;
}

.reception-booking-notes-overlay {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 0 auto;
}

.reception-people-view {
    flex: 1 1 100%;
    display: flex;
    flex-direction: row;
}

.reception-people-view > * {
    flex-basis: 100%
}

.cal-wrapper {
        /*flex: 1 0 auto;*/
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: stretch;
        overflow: auto;
        /*    position: absolute;
    top: 45px;
    left: 0;
    right: 0;
    bottom: 15px;
    overflow-y: visible;*/
    }

.cal-body-scroll-wrapper {
    /*position: absolute;
    top: 31px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;*/
}

.cal-body {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    overflow: hidden;
    width: 100%;
}

.cal-notes {
    flex: 0 0 auto;
    min-height: 50px;
    max-height: 150px;
    border: solid #ccc 1px;
    border-radius: 4px;
    margin: 5px;
    padding: 5px;
    white-space: pre;
    text-overflow: ellipsis;
    overflow: auto;
}

@media print {
    .cal-notes {
        max-height: none;
    }
}
.cal-notes-placeholder {
    color: #ccc;
}

.cal-times-scroll-wrapper {
    flex: 0 0 auto;
    overflow: hidden;
}

.cal-times {
    text-align: right;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    color: #AAAAAA;
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 30px;
}

.cal-time {
    min-height: 24px;
}

.cal-hour {
    font-size: 18px;
    font-weight: bold;
    color: #696969;
}

.cal-header-scroll-wrapper {
    overflow-x: hidden;
    flex: 0 0 auto;
    margin-left: 65px;
}

.cal-header {
    flex: 1 1 auto;
    padding: 0;
    background-color: #F0F0F0;
    border-color: #D1D1D1;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    overflow: hidden;
}

.cal-resource-header {
    height: 30px;
    opacity: 1;
    margin-top: 0;
    padding-left: 10px;
    padding-top: 2px;
    color: white;
    /*overflow: hidden;*/
    float: left;
    height: 50px;
    margin: 0 5px;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.cal-resource-header-times {
    font-weight: bold;
    text-transform: uppercase;
}

.cal-opening-times {
    margin-left: 65px;
    padding: 4px;
    font-size: 18px;
    font-weight: bold;
}

.cal-opening-times .closed {
    background-color: red;
    color: white;
    padding: 0 8px;
}

.cal-resource-header > h3 {
    margin: 0;
}

.cal-events-scroll-wrapper {
    flex: 1 1 auto;
    overflow: auto;
}

.cal-resource-lane {
    border-right: solid 1px #bbb;
    float: left;
    position: absolute;
    height: 100%;
}

.cal-events {
    padding: 0;
    background-color: #F0F0F0;
    border-color: #D1D1D1;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
}

.cal-resource {
    float: left;
    height: 100%;
    margin: 0 5px;
    position: relative;
}

.cal-resource-events {
    float: left;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
}

.cal-reservation {
    z-index: 450;
    cursor: pointer;
    position: absolute;
    border-left-width: 5px;
    border-style: solid;
    border-top-width: 1px;
    border-top-color: #D1D1D1;
    border-right-width: 1px;
    border-right-color: #D1D1D1;
    border-bottom-width: 1px;
    border-bottom-color: #D1D1D1;
    overflow: hidden;
    text-decoration: none;
    padding: 0;
    line-height: 18px;
}

.cal-reservation:hover {
    text-decoration: none;
}

.cal-reservation-content {
    padding: 0 4px;
    height: 100%;
}

.cal-reservation-start {
    width: 40px;
}

.cal-reservation-heading {
    margin: 0 -4px;
    padding-left: 4px;
    margin-bottom: 2px;
    color: #000;
    font-weight: bold;
    border-bottom: solid 1px #999;
    position: relative;
}

.cal-reservation-details {
    margin-top: 3px;
    border-top: solid 1px #ccc;
}

.cal-reservation-text {
    position: relative;
    margin-right: 8px;
}

.cal-reservation-counts {
    display: flex;
    flex-wrap: nowrap;
    float: right;
}

.cal-reservation-info {
    position: relative;
    display: inline-block;
    margin-right: 5px;
    border: 1px solid #bbb;
    border-radius: 4px;
    background: #efefef;
    padding: 0 4px;
    font-weight: normal;
    text-align: center;
}

.cal-reservation-icon {
    margin-right: 5px;
}

.cal-reservation-gap {
    position: absolute;
    z-index: 99;
    background: repeating-linear-gradient( -35deg, #f6ba52, #f6ba52 10px, #ffd180 10px, #ffd180 20px );
}

.file-drop {
    width: 185px;
    height: 120px;
    border-width: 2px;
    border-color: #666666;
    border-style: dashed;
    border-radius: 5px;
    display: inline-block;
    z-index: 400;
}

.file-preview-wrapper {
    position: relative;
    display: inline-block;
}

    .file-preview-wrapper:hover {
        border: 1px solid #555;
    }

.file-preview {
    max-width: 200px;
    max-height: 120px;
    margin-left: 15px;
    vertical-align: top;
}

.activity-format-variations {
    margin-bottom: 15px;
    min-width: 600px;
}

.activity-format-variation-list-item {
    border: solid 1px #ccc;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 #e8ebed;
    padding: 5px;
    margin-bottom: 8px;
}

.activity-format-variation-schedule-item {

}

.activity-format-variation-schedule-item-break {
    border-top: solid 1px #ccc;
}

.registration-page {
    height: 100%;
    background-size: cover;
    overflow: auto;
    padding: 25px;
}

.registration-content {
    position: relative;
    height: 100%;
    overflow: auto;
}

.registration-page-kiosk-name {
    position: fixed;
    bottom: 4px;
    right: 4px;
    color: #888;
}

.registration-page::before {
    content: '';
    opacity: 0.50;
    background-color: #000000;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.registration-kiosk-settings-list {
    width: 100%;
    list-style: none;
    display: table;
    border-collapse: collapse;
    padding: 0;
}

.registration-kiosk-setting {
    display: table-row;
}

.registration-kiosk-setting-name {
    display: table-cell;
}

.registration-kiosk-setting-selection {
    display: table-cell;
}

.registration-login-wrapper {
    color: #bbb;
    background-color: rgba(0,0,0,0.8);
    border-radius: 8px;
    overflow: auto;
    margin: 20px auto;
    max-width: 850px;
    padding: 30px;
}

.registration-header-center {
    text-align: center;
    margin-bottom: 15px;
}

.registration-header-right {
    text-align: right;
    margin-bottom: 15px;
}

.registration-step-header {
    float: left;
    text-align: left;
}

.registration-header-image {
    height: 90px;
}

.registration-step-title {
    font-size: 20px;
    margin-right: 10px;
}

.time-label {
    text-align: center;
}


.event-button {
    border-style: solid;
    border-width: 0 0 0 4px;
    padding: 0 4px;
}

.large-event-button {
    border-style: solid;
    border-width: 1px 1px 1px 5px;
    margin: 5px 0;
    padding: 0 5px;
    min-height: 60px;
    background-color: #fff;
}

.event-button-heading {
    margin: 0 -5px;
    padding-left: 5px;
    margin-bottom: 3px;
    border-bottom: solid 1px #ccc;
}

.event-button-time {
}

.event-button-name {
    margin-left: 6px;
}

.event-booking-button {
    border-style: solid;
    border-width: 1px;
    margin: 5px 0;
    padding: 0 5px;
    min-height: 30px;
    background-color: #fff;
}

.event-booking-button span {
    display: inline-block;
}

.customer-bill {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 5px;
}

.numeric-pad-wrapper {
    position: relative;
    display: inline-block;
}

.numeric-pad-wrapper .btn {
    display: inline-block;
    height: 65px;
    width: calc(33% - 4px);
    margin: 2px;
    font-size: 20pt;
    font-weight: bold;
}


.pos_bill_panel {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    width: 315px;
}

.pos_flex_wrapper {
    display: flex;
    align-items: stretch;
    align-content: stretch;
    height: 100%;
    width: 100%;
}

.pos-bill-selection {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
}

.pos-bill-selection-header {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
}

.pos-bill-selection-header div {
    flex: 1 1 50%;
    padding: 0 15px;
}

.pos-bill-selection-content {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
}

.pos-bill-selection-content div {
    flex: 1 1 50%;
    padding: 0 15px;
}

.pos-btn {
    height: 65px;
}

.pos-selection-btn {
    font-size: 28pt;
    font-weight: 600;
}

.pos_error {
    padding: 0 10px;
    font-size: 16px;
    font-weight: bold;
}

.pos_payment_panel {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    align-content: stretch;
    max-width: 900px;
}

.pos_payment_panel_payment_name {
    background-color: #d9534f;
    color: white;
    padding: 2px 8px 2px 0;
}

.pos_payment_panel .btn {
    font-size: 22pt;
    font-weight: bold;
    white-space: normal;
    line-height: 26pt;
}

.pos_payment_main_panel {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: stretch;
    align-content: stretch;
    height: 100%;
    padding: 4px;
    border-left: 1px solid #ccc;
}

.pos_payment_types {
    max-width: 150px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-content: stretch;
}

.pos_payment_types > * {
    flex: 1 auto;
    margin: 5px;
    padding: 3px;
}

.pos_payment_header {
    font-size: 20pt;
    padding: 4px;
}

.pos_payment_change_panel {
    min-height: 78px;
    background: darkorange;
    border-radius: 6px;
    padding: 4px;
    color: white;
    text-align: center;
    font-size: 34pt;
    font-weight: 700;
}

.pos_payment_buttons {
    display: flex;
    flex: 1 auto;
    flex-direction: row;
    align-items: stretch;
    align-content: stretch;
}

.pos_payment_buttons > * {
    flex: 1 auto;
}

.pos_payment_number_buttons {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    align-content: stretch;
}

.pos_payment_button_col {
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-content: stretch;
}

.pos_payment_button_col > * {
    flex: 1 auto;
    margin: 5px;
}

.pos_payment_button_col .btn {
    font-size: 38pt;
    font-weight: 900;
}

.pos_payment_cash_buttons {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-content: stretch;
    max-width: 80px;
}

.pos_payment_cash_buttons > * {
    flex: 1 auto;
    margin: 5px;
}

.pos_payment_cash_buttons .btn {
    font-size: 36pt;
    padding: 4px;
}


.pos_payment_split_buttons {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-content: stretch;
    max-width: 80px;
}

.pos_payment_split_buttons > * {
    flex: 1 auto;
    margin: 5px;
}

.pos_payment_split_buttons .btn {
    font-size: 26pt;
    padding: 4px;
}

.pos_payment_actions {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    align-content: stretch;
}

.pos_payment_actions > * {
    flex: 1 auto;
    margin: 5px;
}

.pos_wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.pos_overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
}

.pos_overlay_content {
    background: white;
    border-radius: 8px;
    min-height: 150px;
    min-width: 350px;
    padding: 25px;
    margin: 25px;
    flex: 1 1 auto;
    display: flex;
}

.pos_alert {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
}

.pos_alert_content {
    background: white;
    border-radius: 8px;
    border: solid 2px red;
    min-height: 150px;
    min-width: 350px;
    padding: 25px;
    margin: 25px;
    flex: 1 1 auto;
    display: flex;
}

.pos_categories_panel {
    flex: 0 0 auto;
    display: flex;
    width: 142px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
}

.pos_products_panel {
    flex: 1 auto;
    display: flex;
    flex-direction: column;
}

.pos_category_list {
    overflow-y: auto;
    flex: 1 auto;
}

.pos_category_item {
    width: 120px;
    margin: 6px auto;
    min-height: 65px;
    padding: 6px;
    border-radius: 6px;
    font-weight: 700;
    line-height: 49px;
}

.pos_category_item > span {
    display: inline-block;
    vertical-align: middle;
    line-height: 20px;
}

.pos_product_list {
    overflow-y: auto;
    flex: 1 auto;
    display: flex;
    flex-wrap: wrap;
}

.pos_product_item {
    width: 148px;
    margin: 3px 2px;
    min-height: 75px;
    border: solid 1px #333;
    background-color: white;
    border-radius: 6px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    overflow: hidden;
    font-weight: 700;
}

.pos_product_item_text {
    background: rgba(230,230,230,0.7);
    text-align: center;
}

.pos_bill_heading {
    font-size: 16px;
    font-weight: bold;
    padding: 4px;
    border-bottom: solid 2px #333;
}

.pos_bill_item_list {
    overflow: auto;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
}

.pos_bill_item {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 2px 4px 6px 4px;
}

.pos_bill_refund {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 2px 4px 6px 4px;
}

.pos_bill_item_separator {
    color: #000;
    font-weight: 700;
    border-bottom: solid 1px #888;
}

.pos_bill_item_qualtity {
    font-size: 14pt;
    min-width: 22px;
}

.pos-stripe-payment-method {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pos-stripe-reader-list {
    list-style: none;
    padding: 0;
}

.pos-stripe-reader-list li .btn {
    padding: 10px;
    border: solid 1px #666;
    border-radius: 3px;
}

.pos-stripe-reader-list li .btn.online {
    background-color: lawngreen;
}

.pos-stripe-reader-list li .btn.offline {
    background-color: #fb5151;
}

.pos-stripe-reader-status {
    margin-top: 10px;
    text-align: right;
    font-size: smaller;
}

.bill_item_name {
    flex: 1 1 0;
    margin: 2px 8px 0 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: start;
}

.bill_item_name button {
    white-space: pre-wrap;
    text-align: left;
}

.bill-payment-item {
    flex: 1 1 auto;
    display: flex;
}

.bill-payment-item-name {
    margin: 0 6px;
}

.bill-payment-item-details {
    flex: 1 1 auto;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
}

.bill-payment-item-price {
    flex: 0 0 auto;
}

.bill_item_actions {

}

.bill_item_price {
    font-size: 14pt;
    color: black;
    display: flex;
    flex-direction: column;
}

.bill_item_main_price {
    line-height: 18px;
}

.bill_item_tax {
    text-align: right;
    font-size: 12px;
    color: #777;
    margin: 0;
    line-height: 14px;
}

.bill_item_voucher_price {
    font-size: 12pt;
    color: #888;
}

.bill_refund_item_details {
    flex: 0 1 100%;
    font-size: 9pt;
    text-align: right;
}

.pos_action_buttons {
    display: flex;
    height: 50px;
}

.pos_action_button {
    flex: 1 0 0;
    margin: 5px;
    cursor: pointer;
}

.pos_totals {
    margin-right: 10px;
}

.pos_main_total {
    font-size: 16pt;
}

.pos_total_label {
    font-weight: bold;
    margin-right: 80px;
}

.pos_total_value {
    float: right;
}

.pos-process-gateway-payment-panel {
    flex: 1 1 100%;
}

.payment-method-page {
    overflow: auto;
}

.payment-method-form-gateway-settings{
    border: solid 1px #CCC;
    border-radius: 5px;
    max-width: 400px;
    padding: 10px;    
}

.task-confirmation-panel {
    padding: 30px;
}

.report-total {
    border-top: solid 2px black;
}

.booking-bill-wrapper {
    padding: 2px 6px 6px 10px;
}

.inline-calendar-wrapper {
    flex: 1 1 auto;
    overflow: auto;
}

.diary-calendar-container {
    overflow: hidden;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.add-event-to-booking-panel {
    display: flex;
    flex-direction: row;
}

.add-event-to-booking-panel-calendar {
    flex: 1 1 20%;
    min-width: 250px;
    max-width: 400px;
    display: flex;
    flex-direction: column;
}

.add-event-to-booking-panel-calendar-header {
    flex: 0 0 auto;
}

.add-event-to-booking-date-selection {
    flex: 1 1 auto;
    display: flex;
    flex-wrap: nowrap;
}

.add-event-to-booking-date-selection-button {
    flex: 0 0 auto;
}

.date-picker add-event-to-booking-date-selection-date {
    flex: 1 1 auto;
}

.add-event-to-booking-panel-reservation {
    flex: 1 1 auto;
    padding: 15px;
}

.reschedule-booking-panel {
    display: flex;
    flex-direction: column;
    max-height: 95%;
    margin: 10px;
    border-radius: 6px;
}

.reschedule-booking-header {
    flex: 0 0 auto;
}

.reschedule-booking-reservation-selection {
    flex: 1 1 auto;
    overflow: hidden;
    display: flex;
    min-height: 400px;
}

.reschedule-booking-actions {
    flex: 0 0 auto;
}

.reschedule-booking-panel-calendar {
    flex: 0 1 400px;
    display: flex;
    flex-direction: column;
}

.reschedule-booking-panel-calendar-header {
    flex: 0 0 auto;
}

.reschedule-booking-reservation {
    cursor: pointer;
    background-color: #fff;
}

.reschedule-booking-reservation.selected {
    background-color: #c6e4fe;
}

.reschedule-booking-reservation-heading {
    font-size: 16px;
    font-weight: bold;
    padding: 10px 6px;
}

.reschedule-booking-reservation-heading.rescheduled {
    color: #bbb;
}

.reschedule-booking-date-selection {
    flex: 1 1 auto;
    display: flex;
    flex-wrap: nowrap;
}

.reschedule-booking-date-selection-button {
    flex: 0 0 auto;
}

.date-picker reschedule-booking-date-selection-date {
    flex: 1 1 auto;
}

.reschedule-booking-panel-reservation {
    flex: 1 1 auto;
    padding: 15px;
    overflow-y: auto;
}


.create-event-panel {
    display: flex;
    flex-direction: column;
    max-height: 95%;
    margin: 10px;
    border-radius: 6px;
}

.create-event-header {
    flex: 0 0 auto;
}

.create-event-reservation-selection {
    flex: 1 1 auto;
    overflow: hidden;
    display: flex;
}

.create-event-panel-calendar {
    flex: 1 1 100%;
    display: flex;
}

.create-event-panel-calendar-header {
    flex: 0 0 auto;
}

.create-event-panel-calendar-detail {
    flex: 1 1 auto;
    overflow: auto;
}

.create-event-actions {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
}

.create-event-panel-calendar {
    flex: 1 1 20%;
    min-width: 250px;
    max-width: 400px;
    display: flex;
    flex-direction: column;
}

.create-event-panel-calendar-header {
    flex: 0 0 auto;
}

.create-event-reservation {
    cursor: pointer;
    background-color: #fff;
}

.create-event-reservation.selected {
    background-color: #c6e4fe;
}

.create-event-reservation-heading {
    font-size: 16px;
    font-weight: bold;
    padding: 10px 6px;
}

.create-event-reservation-heading.rescheduled {
    color: #bbb;
}

.create-event-date-selection {
    flex: 1 1 auto;
    display: flex;
    flex-wrap: nowrap;
}

.create-event-date-selection-button {
    flex: 0 0 auto;
}

.create-event-alternate-slots {
    list-style: none;
    margin: 10px 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.create-event-alternate-slot {
    flex: 0 0 auto;
    margin: 4px 6px;
    padding: 8px 15px;
    cursor: pointer;
}

.date-picker create-event-date-selection-date {
    flex: 1 1 auto;
}

.create-event-panel-calendar-detail {
    flex: 1 1 auto;
    overflow: auto;
}

.create-event-panel-reservation {
    flex: 1 1 auto;
    padding: 15px;
    overflow-y: auto;
}

.event-form-bookings-tab {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    margin-bottom: 8px;
    /* No idea how this works, but it seems to make the layout work (it still grows if the page is bigger!)*/
    height: 450px;
}

.event-form-registrations-tab {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    margin-bottom: 8px;
    /* No idea how this works, but it seems to make the layout work (it still grows if the page is bigger!)*/
    height: 450px;
    overflow: auto;
}

.event-form-registrations-tab-registrations {
    flex: 1 1 auto;
    height: 450px;
    overflow: auto;
}

.event-form-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 20px;
}

.event-form-bookings-tab-customers {
    flex: 1 1 33%;
    max-width: 320px;
    max-height: 100%;
    overflow: auto;
}

.event-form-bookings-tab-booking {
    flex: 1 1 66%;
    margin-left: 8px;
    overflow-y: auto;
    overflow-x: clip;
}


.event-form-booking-header {
    display: flex;
    flex-direction: column;
}

.event-form-booking-header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.event-form-booking-header-cancelled-banner {
    background-color: red;
    color: white;
    padding: 4px;
    font-size: 16px;
    text-align: center;
    border-radius: 0 0 6px 6px;
}

.event-form-booking-header-cancellation-reason {
    font-size: 14px;
}

.event-form-booking-header-customer {
    flex: 1 1 100%;
    align-items: center;
}

.event-form-booking-header-booking-info {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}



@media (max-width: 1024px) {
    /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
    .navbar {
    min-height: 0;
    margin-bottom: 0;
    background-color: #333;
}
}

@media (min-width: 1025px) {


    /* On small screens, convert the nav menu to a vertical sidebar */
    .main-nav {
        width: 220px;
        bottom: 0;
    }

        .main-nav .navbar {
            border-radius: 0px;
            border-width: 0px;
            height: 100%;
        }

        /*.main-nav .navbar-header {
float: none;
}*/
        .main-nav .navbar-collapse {
            border-top: 1px solid #444;
            padding: 0px;
        }

        .main-nav .navbar ul {
            float: none;
        }

        .main-nav .navbar li {
            float: none;
            font-size: 15px;
            margin: 6px;
        }

            .main-nav .navbar li a {
                padding: 10px 16px;
                border-radius: 4px;
            }

        .main-nav .navbar a {
            /* If a menu item's text is too long, truncate it */
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .main-nav::before {
            display: block;
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 2;
            opacity: 0.33;
            background-color: #000;
        }

        .main-nav::after {
            display: block;
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-size: 150% 150%;
            opacity: 0.77;
            background: linear-gradient(180deg,#292929,#0e0e0e);
        }

    .navbar {
        z-index: 4;
    }

    .app-content-container {
        left: 220px;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow-x: auto;
    }
}


@media screen {

    .print-only {
        display: none;
    }

}




/* Styles for stripe elements */

.StripeElement {
    box-sizing: border-box;
    height: 40px;
    padding: 10px 12px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

.signature-pad {
    background-color: #fff;
    display: block;
    max-height: 200px;
    max-width: 550px;
}

.signature-display {
    margin: 20px 0;
    display: inline-block;
    background-color: #fff;
    border: solid 1px black;
}

.signature-display img {
    max-width: 250px;
}


.onoffswitch {
    position: relative;
    width: 150px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.onoffswitch-checkbox {
    display: none;
}

.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #999999;
    border-radius: 8px;
}

.onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner span {
    display: block;
    float: left;
    width: 50%;
    height: 35px;
    padding: 0;
    line-height: 35px;
    font-size: 15px;
    font-weight: bold;
    box-sizing: border-box;
}

.onoffswitch-inner .onoffswitch-inner-on {
    padding-left: 12px;
}

.onoffswitch-inner .onoffswitch-inner-off {
    padding-right: 12px;
    text-align: right;
}

.onoffswitch-switch {
    display: block;
    width: 14px;
    margin: 10.5px;
    background: #FFFFFF;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 111px;
    border: 2px solid #999999;
    border-radius: 8px;
    transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px;
}

.venues-page {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.tabs {
    display: flex;
    flex-direction: column;
}

.tab-headers {
    flex: 1 1 100%;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 10px 0 0 0;
    padding: 0;
}

.tab {
    flex: 1 1 auto;
    padding: 10px 15px;
    text-align: center;
    cursor: pointer;
}

.tab.selected {
    background-color: white;
}

.tab-content {
    background-color: white;
}

.add-tag-popup {
    position: relative;
    z-index: 2;
    max-width: 400px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px;
    padding: 10px;
}

.tag-list {
    margin: 0;
}

.tag-list-inline {
    display: flex;
    flex-wrap: wrap;
}

.tag-label {
    margin-right: 6px;
}

.bookng-notes-list {
    overflow: auto;
    max-height: 250px;
    list-style: none;
    padding-left: 0;
    margin-bottom: 10px;
}

.booking-note-list-item {
    border-top: solid 1px #ddd;
    cursor: pointer;
    padding: 6px 5px;
}

.booking-note-list-item.selected {
    border-left: solid 3px #337ab7;
    padding-left: 2px;
}

.custom-booking-notes-view {
    overflow: auto;
    padding: 6px;
    min-height: 40px;
    max-height: 80px;
    width: 100%;
    border: solid 1px #ccc;
    border-radius: 4px;
}

.daily-summary-time {
    font-size: 18px;
    font-weight: bold;
}

.daily-summary-count {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}


.edit-opening-times-standard-hours {
    font-size: 18px;
    font-weight: bold;
}

.email-template-editor-wrapper {
    width: 100%;
}

.email-template-editor-frame {
    width: 100%;
    min-height: 900px;
    overflow: auto;
}

.campaign-customer-count {
    display: inline-block;
    margin-left: 15px;
    font-size: 18px;
}

.campaign-customer-count span {
    color: #fa8018;
    font-weight: bold;
}

.campaign-excluded-customer-count {
    display: inline-block;
    font-size: 14px;
    margin-left: 15px;
}

.campaign-filter-group {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
}

.campaign-filter-group-left-border {
    flex: 0 0 10px;
    border-style: solid;
    border-color: #222;
    border-width: 1px 0 1px 1px;
    margin-right: 6px;
}

.campaign-filter-group-right-border {
    flex: 0 0 10px;
    border-style: solid;
    border-color: #222;
    border-width: 1px 1px 1px 0;
    margin-left: 6px;
}

.campaign-filter-group-content {
    flex: 1 1 auto;
}

.campaign-filter {
    padding: 0;
    margin: 5px 0;
    display: flex;
    flex-direction: row;
}

.campaign-filter-left-border {
    flex: 0 0 10px;
    border-style: solid;
    border-color: #222;
    border-width: 1px 0 1px 1px;
    margin-right: 6px;
}

.campaign-filter-right-border {
    flex: 0 0 10px;
    border-style: solid;
    border-color: #222;
    border-width: 1px 1px 1px 0;
    margin-left: 6px;
}

.campaign-filter-content {
    flex: 1 1 auto;
}

.campaign-selected-venues {
    list-style: none;
    margin: 6px 0 0 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.campaign-total {
    font-size: 16px;
    font-weight: bold;
}

.campaign-selected-venues li {
    margin: 3px 5px;
    border: solid 1px #666;
    border-radius: 4px;
    background-color: #eee;
    padding: 3px 5px;
}

.email-template-editor-frame-loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 80px;
    text-align: center;
    background-color: rgba(4,4,4,0.6);
    color: #fff;
    font-size: 18px;
}

.sep + .sep {
    margin: 0 5px;
    border-left: 1px solid black;
    padding: 0 5px;
}




.customer-new-booking {
    display:flex;
    flex-direction: column;
    overflow: hidden;
}

.create-event-scroll-wrapper {
    flex: 1 1 auto;
    overflow: auto;
}

.customer-new-booking-header {
    flex: 1 0 auto;
    padding-block: 8px;
}

.customer-new-booking-calendar {
    flex: 1 1 auto;
    overflow: hidden;
    display: flex;
}

.customer-new-booking-footer {
    flex: 1 0 auto;
    padding-top: 8px;
}

.customer-filters {
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    justify-content: space-between;
}

.customer-filter {
    flex: 0 0 auto;
    margin: 0 4px;
    padding: 0 5px;
}

.customer-filter-actions {
    flex: 0 0 auto;
}

.customer-filter-actions button {
    margin: 0 4px;
}

.promotion-page-customer-restriction {
    display: flex;
    margin: 0 0 10px 0;
    align-items: start;
}

.promotion-page-customer-restriction-selection {
    max-width: 300px;
}

.promotion-page-customer-restriction-text {
    margin: 0 10px;
}

.promotion-page-customer-restriction-value {
}

.promotion-page-customer-restriction-value-selection {
    margin: 0 10px;
}

.analytics-settings-table {
    width: 100%;
    max-width: 100%;
}

.analytics-settings-table td {
    vertical-align: top;
    padding: 0 5px;
}

.analytics-page-content {
    display: flex; 
    flex-direction: row;
    height: 100%;
    margin: 0 -15px;
}

.analytics-page-report-selection {
    flex: 0 0 200px;
    border-right: solid 1px #ccc;
    display: flex;
}

.analytics-page-report-content {
    flex: 1 1 auto;
    display: flex;
}

.analytics-report-selection {
    margin-top: 30px;
    list-style: none;
    padding: 0;
    overflow: auto;
    flex: 1;
}

.analytics-report-selection-heading {
    font-weight: bold;
    margin-top: 10px;
}

.analytics-report-selection-report {
    margin-left: 15px;
}   

.analytics-report-content {
    flex: 1 1 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.analytics-report-content-header {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    padding: 5px 4px;
    align-items: stretch;
    background-color: #fff;
    border-bottom: solid 1px #ccc;
}

.analytics-report-content-header-name {
    font-weight: bold;
    padding-left: 8px;
    flex: 1 1 100%;
}

.analytics-report-content-header-period-selection-wrapper {
    flex: 1 1 100%;
    display: flex;
    padding: 5px 4px;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #fff;
    border-bottom: solid 1px #ccc;
}

.analytics-report-content-header-period-selections {
    display: flex;
}

.analytics-report-content-data {
    flex: 1;
    overflow: auto;
}

.analytics-report-period-selection {
    min-width: 250px;
}

.analytics-report-content-summary {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.analytics-report-content-summary-item {
    border: solid 1px #888;
    border-radius: 3px;
    padding: 15px;
    margin: 0 15px;
    flex: 1 1 auto;
}

.analytics-report-content-summary-item div {
    font-size: 18px;
    font-weight: bold;
}

.analytics-report-stat {
    border: solid 1px #888;
    border-radius: 3px;
    padding: 10px;
    margin: 0 10px;
    flex: 1 1 auto;
}

.analytics-report-stat-header {
    margin: 0 0 5px 0;
}

.analytics-report-stat-data {

}

.analytics-report-stat-list {
    list-style: none;
    padding: 0;
}

.analytics-report-stat-list-item {
    display: flex;
    justify-content: space-between;
    margin: 3px 0;
}

.analytics-report-stat-label {
    padding-left: 4px;
    white-space: nowrap;
}

.analytics-report-header-table {
    width: 100%;
    max-width: 100%;
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0 15px;
}

.analytics-report-header-table th {
    text-align: center;
}

.analytics-report-header-table th div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
}

.analytics-report-header-table th div span {
    flex: 1 1 auto;
    text-align: center;
}

.analytics-report-header-table th div i {
    flex: 0 0 auto;
    text-align: center;
    margin-top: 3px;
    margin-right: 5px;
    cursor: pointer;
}

.analytics-report-header-table td {
    padding: 3px 0;
    max-height: 1px;
    height: 1px;
}

.analytics-report-header-table tbody td > div {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    margin: 3px 0;
    padding: 4px;
    border-left: 1px solid #ddd;
    white-space: nowrap;
}

.analytics-report-header-table tbody tr > td:first-child > div {
    border-radius: 4px 0 0 4px;
    border-left: 0;
}

.analytics-report-header-table tbody tr > td:last-child > div {
    border-radius: 0 4px 4px 0;
}

.analytics-report-content-chart-wrapper {
    margin: 10px 15px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.analytics-report-data-table {
    margin: 10px 15px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.analytics-report-data-table h4 {
    text-align: center;
    margin: 0;
    padding: 10px 0px;
    font-weight: bold;
    color: white;
    border-radius: 5px 5px 0 0;
}

.analytics-report-data-table th {
    text-align: center;
}

.analytics-report-data-table th.row-heading {
    text-align: left;
}

.analytics-report-data-table td {
    text-align: right;
}

.analytics-report-data-table td.bordered,
.analytics-report-data-table th.bordered {
    border-left: solid 1px #ddd;
}

.analytics-report-data-table td.left {
    text-align: left;
}

.analytics-report-stat {
    border: 0;
}

.negative {
    color: red;
}

.positive {
    color: limegreen;
}

.analytics-custom-tooltip {
    background-color: #ffffff;
    border: solid 1px #666;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px;
    padding: 10px;
}

.analytics-custom-tooltip-header {
    text-align: center;
    font-weight: bold;
}

.analytics-custom-tooltip-item {
    display: flex;
    flex-direction: row;
    align-content: space-between;
    justify-content: space-between;
}

.analytics-custom-tooltip-item-colour {
    border-style: solid;
    border-width: 2px;
    width: 12px;
    height: 4px;
    margin-right: 5px;
}
 