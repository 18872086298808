
.quill {
    max-width: 600px;
}

.quill-dark .ql-editor {
    background-color: rgba(0, 0, 0, 0.8);
    color: #bbb;
}


.ql-editor {
    height: 180px;
}

.ql-align-center {
    text-align: center;
}

.ql-align-justify {
    text-align: justify;
}

.ql-align-right {
    text-align: right;
}

.ql-size-small {
    font-size: 0.75em;
}

.ql-size-large {
    font-size: 1.5em;
}

.ql-size-huge {
    font-size: 2.5em;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before {
    content: 'Small';
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
    content: 'Small';
    font-size: 10px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32px"]::before {
    content: 'Large';
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
    content: 'Large';
    font-size: 18px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32px"]::before {
    content: 'Huge';
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
    content: 'Huge';
    font-size: 32px;
}