/*
    http://brennaobrien.com/blog/2014/05/style-input-type-range-in-every-browser.html
*/

input[type=range] {
    -webkit-appearance: none;
    margin: 0;
    width: 100%;
}

    input[type=range]:focus {
        outline: none;
    }

    input[type=range]::-webkit-slider-runnable-track {
        width: 100%;
        height: 5px;
        background: #ddd;
        border: none;
        border-radius: 3px;
    }

    input[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none;
        border: none;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background: #337ab7;
        margin-top: -5px;
        cursor: pointer;
    }

    input[type=range]::-moz-range-track {
        width: 100%;
        height: 5px;
        background: #ddd;
        border: none;
        border-radius: 3px;
    }

    input[type=range]::-moz-range-thumb {
        border: none;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background: #337ab7;
        cursor: pointer;
    }

    input[type=range]::-ms-track {
        width: 100%;
        height: 5px;
        /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
        background: transparent;
        /*leave room for the larger thumb to overflow with a transparent border */
        border-color: transparent;
        border-width: 6px 0;
        /*remove default tick marks*/
        color: transparent;
    }

    input[type=range]::-ms-fill-lower {
        background: #777;
        border-radius: 10px;
    }

    input[type=range]::-ms-fill-upper {
        background: #ddd;
        border-radius: 10px;
    }

    input[type=range]::-ms-thumb {
        border: none;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background: #337ab7;
    }

    input[type=range]:focus::-ms-fill-lower {
        background: #888;
    }

    input[type=range]:focus::-ms-fill-upper {
        background: #ccc;
    }
