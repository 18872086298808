

.multi_image_viewer_container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
}

.multi_image_viewer_container img {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
    opacity:0;
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
}

.multi_image_viewer_container img.opaque {
    opacity: 1;
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=1);
}

